import { Routes } from '@angular/router';
import { LayoutComponent } from '@pages/layout/layout.component';
import { permissionGuard } from '@lib/guards/permission.guard';
import { authGuard } from '@lib/guards/auth.guard';

export const routes: Routes = [
    {
        path: 'auth',
        loadChildren: async () => (await import('@pages/auth')).routes,
        canMatch: [authGuard({ requiresAuthentication: false })],
    },
    {
        path: '',
        component: LayoutComponent,
        canMatch: [authGuard()],
        children: [
            {
                path: 'card-management',
                data: { permission: ['b:customer'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/card-management')).routes,
            },
            {
                path: 'report',
                data: { permission: ['b:reports'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/analytics')).routes,
            },
            {
                path: 'home',
                data: { permission: [] },
                canActivate: [permissionGuard],
                loadComponent: async () => (await import('@pages/home/home.component')).HomeComponent,
            },
            {
                path: 'customers',
                data: { permission: ['b:customer'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/customer-management')).routes,
            },
            {
                path: 'card-product',
                data: { permission: ['b:productmgmt'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/product-management')).routes,
            },
            {
                path: 'control-center',
                data: { permission: ['b:accessmgmt'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/control-center')).routes,
            },
            {
                path: 'feedback',
                data: { permission: ['b:feedback'] },
                canActivate: [permissionGuard],
                loadChildren: async () => (await import('@pages/feedback')).routes,
            },
            {
                path: '**',
                redirectTo: 'home',
                pathMatch: 'full',
            },
        ],
    },
    { path: '**', pathMatch: 'full', redirectTo: '/auth/login' },
];
