import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        if (typeof value !== 'string') {
            throw new Error('Function not implemented.');
        }

        return value.substring(0, 1).toUpperCase() + value.substring(1, value.length);
    }
}
