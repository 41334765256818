<div class="sidebar">
    <div class="sidebar-content">
        <div class="d-flex align-items-center sidebar-header-logo">
            <img class="sidebar-logo" [src]="imgUrl" />
        </div>
        <mat-list>
            <ng-container *ngFor="let item of upperMenuItems">
                <div *appAccessControlHideElementDirective="item?.permissions">
                    <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link" routerLinkActive="active">
                        <div class="sidebar-item-content">
                            <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                            <span>{{ item.title }}</span>
                        </div>
                    </mat-list-item>
                </div>
                <mat-expansion-panel *ngIf="item.children" class="sidebar-item-expand" [class.mat-elevation-z0]="true" [class.active-expand]="checkExpandedItem('card-management')" hideToggle>
                    <mat-expansion-panel-header class="sidebar-item-expand-header">
                        <mat-icon class="side-bar-icon" svgIcon="customer"></mat-icon>
                        <span class="sidebar-item-expand-title">Customers</span>
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <mat-list-item *ngFor="let childItem of item.children" class="sidebar-item-expand-sub" [routerLink]="childItem.link" routerLinkActive="active-sub">
                            <span class="sidebar-item-expand-text">{{ childItem.title }}</span>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-expansion-panel>
                <mat-divider *ngIf="item.line" class="sidebar-divider"></mat-divider>
            </ng-container>
        </mat-list>
        <div class="sidebar-footer">
            <mat-list>
                <ng-container *ngFor="let item of bottomMenuItems">
                    <div *appAccessControlHideElementDirective="item?.permissions">
                        <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link" routerLinkActive="active">
                            <div class="sidebar-item-content">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span>{{ item.title }}</span>
                            </div>
                        </mat-list-item>
                    </div>
                    <mat-expansion-panel *ngIf="item.children" class="sidebar-item-expand" [class.mat-elevation-z0]="true" [class.active-expand]="checkExpandedItem('control-center')" [hideToggle]="true">
                        <mat-expansion-panel-header class="sidebar-item-expand-header">
                            <mat-icon class="side-bar-icon" [svgIcon]="item.icon"></mat-icon>
                            <span class="sidebar-item-expand-title" routerLinkActive="active">{{ item.title }}</span>
                        </mat-expansion-panel-header>
                        <mat-nav-list>
                            <mat-list-item *ngFor="let childItem of item.children" class="sidebar-item-expand-sub" [routerLink]="childItem.link" routerLinkActive="active-sub">
                                <span class="sidebar-item-expand-text">{{ childItem.title }}</span>
                            </mat-list-item>
                        </mat-nav-list>
                    </mat-expansion-panel>
                </ng-container>
            </mat-list>
            <p>
                Powered by
                <img src="../../../../assets/Ncore-logo.svg" alt="" />
                <span>nCore</span>
            </p>
        </div>
    </div>
</div>
