import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appAccessControlHideElementDirective]',
})
export class AccessControlHideElementDirective {
    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    @Input() set appAccessControlHideElementDirective(permissions: string[] | undefined) {

        const allPermissions = localStorage.getItem('permissions')?.split(',');
        const hasPermission = allPermissions?.find((permission) => permissions?.includes(permission));

        if (hasPermission || permissions?.includes('b:home')) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
