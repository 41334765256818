import { HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
    const router = inject(Router);
    const accessToken = localStorage.getItem('accessToken') || '';
    const customToken = localStorage.getItem('customToken') || '';
    if (!accessToken) {
        request = request.clone({ headers: request.headers.set('X-Consumer-Username', 'tenant0_api') });
        request = request.clone({ headers: request.headers.set('Authorization', `${customToken}`) });
    }
    if (accessToken) {
        request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) });
    }
    return next(request).pipe(
        tap({
            error: ({ status, ...err }) => {
                if (status === 401) {
                    router.navigateByUrl('auth/login');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('selectedUser');
                    localStorage.removeItem('customToken');
                } else if (status === 403 && err.error.detail === 'Current session is expired, please login again.') {
                    router.navigateByUrl('auth/login');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('selectedUser');
                    localStorage.removeItem('customToken');
                }
            },
        }),
    );
};
