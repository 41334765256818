import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { ApiResponseError } from '@lib/interfaces/error.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
    private subscriptions: Array<Subscription> = [];

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => {
            subscription.unsubscribe();
        });
    }

    protected addSubscription(...subscriptions: Array<Subscription>): void {
        this.subscriptions.push(...subscriptions);
    }

    protected createApiErrorSnackBar(snackBar: MatSnackBar, error: ApiResponseError): void {
        snackBar.open(error.error ? error.error.detail : 'Something went wrong! Please try again', 'Close', {
            duration: 7000,
        });
    }

    protected createApiSuccessSnackBar(snackBar: MatSnackBar, message: string): void {
        snackBar.open(message, 'Close', {
            duration: 7000,
        });
    }

    protected controlHasError<T>(control: FormControl<T>, validator: string): boolean {
        return control.touched && control.hasError(validator);
    }
}
