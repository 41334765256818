<div class="header-wrapper" [style.background]="primary_color">
    <div class="header">
        <div class="header-back">

            <ng-container *ngIf="isRouteMatch('/home')">
                <div class="row">
                    <span class="header-title">1,980</span>
                    <span class="header-desc">ACCOUNT CREATED</span>
                </div>
                <div class="row ml-12">
                    <span class="header-title">2,103</span>
                    <span class="header-desc">CARDS GENERATED</span>
                </div>
                <div class="row ml-12">
                    <span class="header-title">14,203</span>
                    <span class="header-desc">AVERAGE TRANSACTION</span>
                </div>
            </ng-container>

        </div>
        <div class="header-right">
            <mat-icon class="header-icon">search</mat-icon>
            <mat-icon class="header-icon">notifications_none</mat-icon>
            <div
                class="cursor-pointer"
                [matMenuTriggerFor]="menu"
            >
                <img src="./assets/image.png" />
            </div>
            <mat-menu
                #menu="matMenu"
                class="topheadermenu"
            >
                <div class="nym-dropdown">
                    <div class="nym-dropdown-content">
                        <div class="nym-dropdown-item">Profile</div>
                        <div class="nym-dropdown-item">Edit profile</div>
                        <mat-divider class="nym-dropdown-divider"></mat-divider>
                        <div class="nym-dropdown-item">Account settings</div>
                        <div class="nym-dropdown-item">Change password</div>
                        <div
                            class="nym-dropdown-item"
                            (click)="logout()"
                        >
                            Log out
                        </div>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
</div>
