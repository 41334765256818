import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollSubject = new Subject<number>();
  private scrollBottomSubject = new Subject<boolean>();

  scroll$ = this.scrollSubject.asObservable();
  scrollBottom$ = this.scrollBottomSubject.asObservable();

  sendScrollPosition(position: number) {
    this.scrollSubject.next(position);
  }

  sendIsReachedBottom(isBottom: boolean): void {
    this.scrollBottomSubject.next(isBottom);
  }
}
