import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { delay, map, Observable, of } from 'rxjs';
import { IListResponse, IVelocityLimit, Nullable } from '@lib/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ProductManagementService {
    public environment_url: string;
    private _velocityLimits: Nullable<Array<IVelocityLimit>> = null;

    public constructor(private http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    public fetchCardProductById(cardProductId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}`);
    }

    public updateCardProductDetails(cardProductId: string, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}`, payload);
    }

    public fetchVelocityLimits(): Observable<Array<IVelocityLimit>> {
        if (this._velocityLimits) return of(this._velocityLimits);

        return this.http
            .get<IListResponse<IVelocityLimit>>(`${this.environment_url}nfront/v1/proxy/velocitylimits?limit=100`)
            .pipe(
                map(({ data }: IListResponse<IVelocityLimit>) => {
                    this._velocityLimits = data;

                    return data;
                }),
            );
    }

    public fetchProductVelocityLimits(cardProductId: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(
            `${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/velocitylimits`,
        );
    }

    public unlinkVelocityLimitFromCardProduct(
        cardProductId: string,
        velocityLimitIds: Array<string>,
    ): Observable<Array<string>> {
        return this.http
            .post<Array<string>>(
                `${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/velocitylimits:unlink`,
                { ids: velocityLimitIds },
            )
            .pipe(
                map((remainingLimits: Array<string>) => {
                    this._velocityLimits = null;

                    return remainingLimits;
                }),
            );
    }

    public linkVelocityLimitToCardProduct(
        cardProductId: string,
        velocityLimitIds: Array<string>,
    ): Observable<Array<string>> {
        return this.http
            .post<Array<string>>(
                `${this.environment_url}nfront/v1/proxy/cardproducts/${cardProductId}/velocitylimits:link`,
                { ids: velocityLimitIds },
            )
            .pipe(
                map((linkedLimits: Array<string>) => {
                    this._velocityLimits = null;

                    return linkedLimits;
                }),
            );
    }

    public uploadCardSkin(productId: string, payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/cardproducts/${productId}/card_designs`, payload);
    }
}
