import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularMaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from '@lib/components/side-nav/side-nav.component';
import { HeaderComponent } from '@lib/components/header/header.component';
import { ScrollService } from '@lib/services/scroll.service';
import { SettingsService } from "@lib/services/settings/settings.service";
import { BaseComponent } from "@lib/components/base";

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AngularMaterialModule, SideNavComponent, HeaderComponent],
    selector: 'app-home',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {

    constructor(private scrollService: ScrollService, public settingService: SettingsService) {
      super()
    }

    ngOnInit() {
      this.addSubscription(
        this.settingService.getTenantSetting().subscribe({
          next: ({ metadata }) => {
            this.settingService.settingsDetails.emit(metadata[0])
          }
        })
      )
    }

  @HostListener('scroll', ['$event'])
    onScroll(event: Event) {
      const target = event.target as HTMLElement;
      const scrollTop = Math.round(target.scrollTop);
      const scrollHeight = Math.round(target.scrollHeight - window.innerHeight);

      const isBottom = scrollHeight === scrollTop || (scrollHeight - 1) <= scrollTop;

      this.scrollService.sendIsReachedBottom(isBottom);
    }
}
