<mat-drawer-container class="app-container">
    <mat-drawer
        mode="side"
        opened
        class="app-side-nav"
    >
        <app-side-nav />
    </mat-drawer>
    <mat-drawer-content #scrollContainer (scroll)="onScroll($event)">
        <div class="drawer-content">
            <app-header />
            <div class="main-body">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
