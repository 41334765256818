import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../material.module';
import { MenuItem } from '@lib/interfaces/menu-items.interface';
import { SettingsService } from "@lib/services/settings/settings.service";
@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AngularMaterialModule],
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
    imgUrl = ''
    constructor(private router: Router, public settingService: SettingsService) {
      this.settingService.settingsDetails.subscribe({
        next: (item: any) => {
          this.imgUrl = item.logo_file_name
        }
      })
    }
    panelOpenState = false;

    upperMenuItems: MenuItem[] = [
        {
            title: 'Home',
            icon: 'home',
            link: '/home',
            permissions: ['b:home'],
        },
        {
            title: 'Analytics',
            icon: 'report',
            link: '/report',
            permissions: ['b:reports'],
        },
        {
            title: 'Customers',
            icon: 'customer',
            link: '/customers',
            permissions: ['b:customer'],
        },
        {
            title: 'Card Product',
            icon: 'product-management',
            link: '/card-product',
            permissions: ['b:productmgmt'],
            line: true,
        },
    ];

    bottomMenuItems: MenuItem[] = [
        {
            title: 'Control Center',
            icon: 'settings',
            permissions: ['b:accessmgmt'],
            link: '/control-center',
            children: [
                { title: 'Settings', link: '/control-center/settings' },
                { title: 'Access Management', link: '/control-center/access-management' },
            ],
        },
        {
            title: 'Feedback',
            icon: 'feedback',
            permissions: ['b:feedback'],
            link: '/feedback',
        },
    ];

    checkExpandedItem(item: string): boolean {
        return this.router.url.includes(item);
    }
}
