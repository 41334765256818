import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../material.module';
import { SettingsService } from "@lib/services/settings/settings.service";

@Component({
    standalone: true,
    imports: [CommonModule, AngularMaterialModule],
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() drawer: any;
    isOpen = false;
    primary_color = ''
    constructor(private router: Router, public settingService: SettingsService) {}

    ngOnInit() {
      this.settingService.settingsDetails.subscribe(({ primary_color = '' }) => {
        this.primary_color = primary_color
      })
    }

  isRouteMatch(route: string): boolean {
        if (this.router.url === route) return true;

        return false;
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }
    logout(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('customToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('selectedUser');
        this.router.navigate(['/auth/login']);
    }
}
