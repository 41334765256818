import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
    providedIn: 'root',
})
export class AppAssets {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/logo.svg'));
        this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/home.svg'));
        this.matIconRegistry.addSvgIcon(
            'report',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/report.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'card-management',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/card-management.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'customer',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/customer.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'product-management',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/product-management.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'settings',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/settings.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'feedback',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/feedback.svg'),
        );
        this.matIconRegistry.addSvgIcon('ring', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/ring.svg'));
        this.matIconRegistry.addSvgIcon(
            'search',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/search.svg'),
        );
        this.matIconRegistry.addSvgIcon('back', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/back.svg'));
        this.matIconRegistry.addSvgIcon(
            'nymcard_logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/nymcard-logo.svg'),
        );
        this.matIconRegistry.addSvgIcon('star', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/star.svg'));
        this.matIconRegistry.addSvgIcon(
            'treeDot',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/treeDot.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrows',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrows.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'close',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/close.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-up',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-up.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-right',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-right.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-right-long',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-right-long.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-left',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-left.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'arrow-down',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/arrow-down.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'checkbox',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/checkbox.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'double-arrow-down',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/double-arrow-down.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'filter',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/filter.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'remove-filter',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/remove-filter.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'link-card-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/link-card.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'product-management',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/product-management.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'change-card-status-icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/change-card-status-icon.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'mastercard',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mastercard.svg'),
        );
      this.matIconRegistry.addSvgIcon(
        'edit-logo',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/edit-logo.svg'),
      );
      this.matIconRegistry.addSvgIcon(
        'plus',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/plus.svg'),
      );
      this.matIconRegistry.addSvgIcon(
        'inactive-status',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/role-inactive-status.svg'),
      );
      this.matIconRegistry.addSvgIcon(
        'delete-icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/delete-icon.svg'),
      );
    }
}
