<div class="chip-wrapper">
    <div
        class="chip"
        (click)="toggleChipsDropDown()"
        [title]="statusEnumLabels[status ?? '']"
        [ngClass]="{
            'status-active': status === 'ACTIVE',
            'status-suspended': status === 'SUSPENDED' || status === 'INACTIVE' || status === 'PENDINGIDVERIFICATION' || status === 'PENDINGKYC' || status === 'PENDINGKYB',
            'status-blocked': status === 'TERMINATED' || status === 'BLOCKED',
            'status-locked': status === 'LOCK'
        }"
    >
        <div class="status"></div>
        {{ statusEnum[status ?? ''] }}
    </div>
    <div
        class="menu menu-center"
        *ngIf="openChips && isDropDown"
    >
        <div class="menu-content">
            <div class="menu-title">Manage card status</div>
            <div class="menu-item">
                <div>
                    <div class="status status-active"></div>
                    <span>Active</span>
                </div>
            </div>
            <div class="menu-item">
                <div>
                    <div class="status status-suspended"></div>
                    <span>Suspended</span>
                </div>
            </div>
            <div class="menu-item">
                <div>
                    <div class="status status-blocked"></div>
                    <span>Blocked</span>
                </div>
            </div>
        </div>
    </div>
</div>
